// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Sitemap from "./Sitemap.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as SitemapData from "./SitemapData.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function Sitemap__JsBridge$default(props) {
  var pages = props.pages;
  var pages$1 = React.useMemo((function () {
          return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(pages, Json_Decode$JsonCombinators.array(SitemapData.PagesGroup.decoder)));
        }), [pages]);
  return JsxRuntime.jsx(Sitemap.make, {
              pages: pages$1
            });
}

var $$default = Sitemap__JsBridge$default;

export {
  $$default as default,
}
/* react Not a pure module */
