// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../styleguide/components/Heading/H1.res.js";
import * as H2 from "../../styleguide/components/Heading/H2.res.js";
import * as Link from "../../styleguide/components/Link/Link.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Page from "../../routes/common/Routes_Page.res.js";
import * as SitemapScss from "./Sitemap.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = SitemapScss;

function Sitemap(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H1.make, {
                      children: JsxRuntime.jsx(Link.make, {
                            href: Routes_Page.sitemap,
                            className: css.h1,
                            children: "Datacenters.com Sitemap"
                          })
                    }),
                Belt_Array.mapWithIndex(props.pages, (function (idx, group) {
                        return JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(H2.make, {
                                            children: JsxRuntime.jsx(Link.make, {
                                                  href: group.url,
                                                  className: css.h2,
                                                  children: group.title
                                                })
                                          }),
                                      JsxRuntime.jsx("hr", {}),
                                      JsxRuntime.jsx("ul", {
                                            children: Belt_Array.mapWithIndex(group.pages, (function (index, page) {
                                                    return JsxRuntime.jsx("li", {
                                                                children: JsxRuntime.jsx(Link.make, {
                                                                      href: page.url,
                                                                      children: page.label
                                                                    }),
                                                                className: css.sitemapUrl
                                                              }, String(index));
                                                  })),
                                            className: Cx.cx([
                                                  "list-unstyled",
                                                  css.threeColumnList
                                                ])
                                          })
                                    ]
                                  }, String(idx));
                      }))
              ],
              className: css.container
            });
}

var make = Sitemap;

export {
  css ,
  make ,
}
/* css Not a pure module */
