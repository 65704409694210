// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              label: field.required("label", Json_Decode$JsonCombinators.string),
              url: field.required("url", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Page = {
  decoder: decoder,
  fromJson: fromJson
};

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              title: field.required("title", Json_Decode$JsonCombinators.string),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              pages: field.required("pages", Json_Decode$JsonCombinators.array(decoder))
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var PagesGroup = {
  decoder: decoder$1,
  fromJson: fromJson$1
};

export {
  Page ,
  PagesGroup ,
}
/* decoder Not a pure module */
